import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() mainMessage: string;
  @Input() secondaryMessage: string;

  constructor( private popoverController: PopoverController) { }

  ngOnInit() {
    console.log(this.mainMessage, this.secondaryMessage);
    
  }

}
