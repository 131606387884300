import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskString'
})
export class MaskStringPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    // args[0] es el argumento que indica el numero de caracteres visibles al final del string
      const maskString = value.replace(new RegExp(`.(?=.{${args[0]}})`, 'g'), '*');
      return maskString;
  }
   
}

