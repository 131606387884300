import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Platform, AlertController, MenuController, ModalController, NavController } from "@ionic/angular";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import {
	FuncionesServiceProvider,
	MenuServiceProvider,
	UsuarioServiceProvider,
} from "src/app/service/index.providers";
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { ApiService } from "src/app/api.service";
import { ConexionServiceProvider, ConnectionStatus } from '../../service/conexion-service/conexion-service';

@Component({
	selector: "app-side-menu-mobile",
	templateUrl: "./side-menu-mobile.component.html",
	styleUrls: ["./side-menu-mobile.component.scss"],
})
export class SideMenuMobileComponent implements OnInit, OnDestroy {
	/* Routes = [
    { name: 'Anuncios', path: 'anuncios', icon: 'assets/icon/anuncios.svg' },
    { name: 'Cursos', path: 'cursos', icon: 'assets/icon/cursos.svg' },
    { name: 'Reuniones', path: 'lista-reuniones', icon: 'assets/icon/reunion.svg' },
    { name: 'Progreso', path: 'progreso', icon: 'assets/icon/progreso.svg' },
    { name: 'Cursos Extracurriculas', path: 'cursos-extra', icon: 'assets/icon/cursos_extra.svg' },
    { name: 'Glosario', path: 'glosario', icon: 'assets/icon/glosario.svg' },
    { name: 'Adjuntar Archivo', path: 'adjuntar-archivo', icon: 'assets/icon/clip.svg' },
    { name: 'Ranking', path: 'ranking', icon: 'assets/icon/ranking.svg' },
    { name: 'Diploma', path: 'diploma', icon: 'assets/icon/diploma.svg' },
    { name: 'Ayuda', path: 'ayuda', icon: 'assets/icon/ayuda.svg' },
    { name: 'Preguntas Frecuentes', path: 'faq', icon: 'assets/icon/faq.svg' },
  ] */
	array_menu2$: Observable<any[]>;
	array_menu2: any;
	data_usuario;
	select_page: String = "anuncios";
	new_notification: boolean = false;
	initials: string;
	swipe: boolean = false;
	displayAccordionSection: number | null = null;
	mobile: boolean = false;
	isIos = false;
	groupSelected:number | null = null;
	subGroupSelected:number | null = null;
	notification: boolean = false;
	newNotification: boolean = false;
	@Input()dataUsuarioProp;
	@Input()initialsProp;

	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	lockNetworkToggle: boolean = false;
	private networkObservable: Subscription;
	hasOfflineModeAvailable:boolean = false;
	

	constructor(
		private router: Router,
		private iab: InAppBrowser,
		private mp: MenuServiceProvider,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private up: UsuarioServiceProvider,
		private alertCtrl: AlertController,
		private fp: FuncionesServiceProvider,
		private ns: NotificacionService,
		private api: ApiService,
		private menuCtrl: MenuController,
		private platform: Platform,
		private networkService: ConexionServiceProvider,
	) {
		if (this.platform.is("cordova") || this.platform.is("mobileweb")) this.mobile = true;
		if (this.platform.is("ios")) this.isIos = true;
		if (this.platform.is("cordova")) {
			this.isCordovaApp = true;
			this.checkNewtworkStatus();
		}

		this.up.isLogged.subscribe((res) => {			
			if (res) {
				const user = JSON.parse(localStorage.getItem("user_data"));
				if (user) {
					// console.log("side.ts LOGGED ", this.data_usuario);					
					this.data_usuario = user;
					this.initials = this.data_usuario.nombre.charAt(0).toUpperCase() + this.data_usuario.apellido.charAt(0).toUpperCase();
				};

			} else {
				this.data_usuario = null;
				this.initials = "";
			};

			const configData = JSON.parse(localStorage.getItem("config_data"));
			if(configData && configData.is_offline) this.hasOfflineModeAvailable = true;
			// console.log(configData);
			
		});	
		
	}

	ngOnInit() {
		this.array_menu2$ = this.fp.getPaginas();
		this.array_menu2$.subscribe((paginas) => {
			this.array_menu2 = paginas;
			//console.log("this.array_menu2:::",this.array_menu2);
		});
		
		if (!this.array_menu2) this.array_menu2 = this.fp._pages;
		
		// if (this.array_menu2 && this.networkIsActive && this.isCordovaApp) {
		//  localStorage.setItem("offlineMenu", JSON.stringify(this.array_menu2))
		// } else if(this.isCordovaApp && !this.array_menu2 && !this.networkIsActive && localStorage.getItem("offlineMenu")) {
		// 	this.array_menu2 =  JSON.parse(localStorage.getItem("offlineMenu"));
		// }	
		this.ns.new_notification.subscribe((res) => (this.new_notification = res));
		this.select_page = localStorage.getItem("select_page") || "anuncios";
		if (localStorage.getItem("select_group")) this.groupSelected = parseInt(localStorage.getItem("select_group"));
		if (localStorage.getItem("select_sub_group")) this.subGroupSelected = parseInt(localStorage.getItem("select_sub_group"));
		this.mp.select_group.subscribe((index) => (this.groupSelected = index));		
		this.mp.select_sub_group.subscribe((index) => (this.subGroupSelected = index));	
		
		// this.data_usuario = JSON.parse(localStorage.getItem("user_data"));
		// if (this.data_usuario) {
		// 	console.log("SIDEMENU.TS ONINIT: ", this.data_usuario);
		// 	this.initials = this.data_usuario.nombre.charAt(0).toUpperCase() + this.data_usuario.apellido.charAt(0).toUpperCase();
		// }

		this.ns.showNotification.subscribe((res) => (this.notification = res));
		this.ns.newNotification.subscribe((res) => (this.newNotification = res));
	}

	checkNewtworkStatus() {	
		console.log("sidemenu.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online sidemenu.ts");
				this.networkIsActive = true;
				//   this.offlineManager.checkForEvents().subscribe();
			} else {
				console.log("offline sidemenu.ts");
				this.networkIsActive = false;
			}
		});
	}

	toggleNetwork() {
		if(this.networkService.getDeviceNetworkType() !== "none") {
			this.lockNetworkToggle = false;
			this.networkIsActive = 	!this.networkIsActive;
			console.log("toggle network: ", this.networkIsActive);	
			if(!this.networkIsActive) {
				localStorage.setItem("chooseOffline", "true");
			} else {
				localStorage.removeItem("chooseOffline");
			};
			this.networkService.updateNetworkStatus(this.networkIsActive ? ConnectionStatus.Online : ConnectionStatus.Offline)
		} else {
			this.lockNetworkToggle = true;
			localStorage.removeItem("chooseOffline");
		};
	}

	toOfflineCourses() {
		this.router.navigate(['cursos']);
		this.cerrarMenu();
	}

	to(routeLink, groupIndex = null, subGroupIndex = null) {

		groupIndex ? this.groupSelected = groupIndex : this.groupSelected = this.displayAccordionSection;
		this.subGroupSelected = subGroupIndex;
		
		// if (routeLink == "adjuntar-archivo-from-gestor") {
		// 	let token = localStorage.getItem("token");
		// 	let url = environment.SERVICE_BASE_GESTOR + "adjuntar_archivo?" + "token=" + token;
		// 	console.log(url);
		// 	this.iab.create(url, "_system"); //_blank
		// } else {
			this.mp.select_page.emit(routeLink);
			this.mp.select_group.emit(this.groupSelected);
			this.mp.select_sub_group.emit(this.subGroupSelected);
			// this.modalCtrl.dismiss();
			//this.displayAccordionSection = null;
			this.cerrarMenu();
			if (routeLink == "extracurricular" || routeLink == "libres") {
				let extras: NavigationExtras = {
					queryParams: {
						categoria: routeLink,
					},
				};
				this.router.navigate(["progreso"], extras);
			} else {
				this.router.navigate([routeLink]);
			}
		// }
	}
	
	accordeonActive(index) {
		if(index === this.displayAccordionSection){
			this.displayAccordionSection = null;
		} else{
			this.displayAccordionSection = index;
		}
	}

	async confirmarSalir() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();

		try {
			this.modalCtrl.dismiss();			
		} catch (error) {
			console.log(error);			
		};
	}

	async cerrarSesion() {
		this.cerrarMenu();
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.navCtrl.navigateRoot("login");
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
		
	}

	showNotification(){
		this.notification = !this.notification;
		this.ns.changeShowNotification(this.notification);
		this.cerrarMenu();
	}

	cerrarMenu() {
		this.menuCtrl.close();
	}

	ngOnDestroy() {
		if(this.networkObservable) {
			this.networkObservable.unsubscribe();
		}
	}
}
