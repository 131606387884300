import { Component, OnInit, Input, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import {fromEvent} from 'rxjs';
import { tap, switchMap, takeUntil, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-basic-draw',
  templateUrl: './basic-draw.component.html',
  styleUrls: ['./basic-draw.component.scss'],
})
export class BasicDrawComponent implements OnInit {
  @Input()title: string | null = "";
  @Input()subtitle: string | null = "";
  @Input()description: string | null = "";
  @Input()buttonText: string | null = "";
  @Input()customStyle: string | null = null;
  // modalMessages: string[] | null = null;
  @Input()projectedContent: TemplateRef<any>;

  @ViewChild('canvasss', {static: true}) canvasss: ElementRef<HTMLCanvasElement>; 
  ctxxx: CanvasRenderingContext2D;
  firstFlag:boolean = true;
  drawFirstFlag:boolean = false;

  constructor(private modalCtrl: ModalController, private navParams: NavParams,) { }

  ngOnInit() {
   }

  ngAfterViewInit() {
    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    this.canvasss.nativeElement.width = clientWidth >= 1280 ? 530 : clientWidth >= 1024 ? 448 : clientWidth >= 768 ? 304 : document.documentElement.clientWidth * 0.8;
    this.canvasss.nativeElement.height = clientHeight >= 768 ? 390 : clientHeight * 0.6;
    this.ctxxx = this.canvasss.nativeElement.getContext('2d');

    this.ctxxx.clearRect(0, 0, this.canvasss.nativeElement.width, this.canvasss.nativeElement.height);

    const mouseDownStream = fromEvent(this.canvasss.nativeElement, 'mousedown');
    const mouseMoveStream = fromEvent(this.canvasss.nativeElement, 'mousemove');
    const mouseUpStream = fromEvent(window, 'mouseup');
    mouseDownStream.pipe(
      tap((event: MouseEvent) => {
        if(!this.drawFirstFlag){
          this.watermark(false);
          this.drawFirstFlag = true;
        }
        this.ctxxx.beginPath();
        this.ctxxx.strokeStyle = 'black';
        this.ctxxx.lineWidth = 2;
        this.ctxxx.lineJoin = 'round';
        this.ctxxx.moveTo(event.offsetX, event.offsetY);
      }),
      switchMap(() => mouseMoveStream.pipe(
        tap((event: MouseEvent) => {
          this.ctxxx.lineTo(event.offsetX, event.offsetY);
          this.ctxxx.stroke();
        }),
        takeUntil(mouseUpStream),
        finalize(() => {
          this.ctxxx.closePath();
        })
      ))
    ).subscribe();

    this.canvasss.nativeElement.addEventListener("touchstart", (e) => {
     const mousePos = this.getTouchPos(this.canvasss.nativeElement, e);
    var touch = e.touches[0];
    var mouseEvent = new MouseEvent("mousedown", {
      clientX: touch.clientX,
      clientY: touch.clientY
    });
    this.canvasss.nativeElement.dispatchEvent(mouseEvent);
    }, false);
    this.canvasss.nativeElement.addEventListener("touchend", (e) => {
    var mouseEvent = new MouseEvent("mouseup", {});
    this.canvasss.nativeElement.dispatchEvent(mouseEvent);
    }, false);
    this.canvasss.nativeElement.addEventListener("touchmove", (e) => {
    var touch = e.touches[0];
    var mouseEvent = new MouseEvent("mousemove", {
      clientX: touch.clientX,
      clientY: touch.clientY
    });
    this.canvasss.nativeElement.dispatchEvent(mouseEvent);
    }, false);

    // this.canvasss.nativeElement.addEventListener('click',this.watermark,false);
    this.watermark(false);
  }

  getTouchPos(canvasDom, touchEvent) {
    var rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top
    };
  }

  watermark(e) {
    if (this.firstFlag) {
        this.ctxxx.fillStyle = '#999';
        this.ctxxx.font = '20px';
        this.ctxxx.textAlign = 'center';
        this.ctxxx.fillText('Usa tu dedo o mouse en esta área', this.canvasss.nativeElement.width/2, this.canvasss.nativeElement.height/2);
        // this.ctxxx.beginPath();
        // this.ctxxx.moveTo(66.2352,13.6677);
        // this.ctxxx.bezierCurveTo(60.7296,14.0413,52.8749,19.5078,48.8998,21.3015);
        // this.ctxxx.bezierCurveTo(45.8069,22.7001,43.139,23.9066,40.8114,23.9066);
        // this.ctxxx.bezierCurveTo(38.4093,23.9066,38.0479,22.177,38.5474,18.3654);
        // this.ctxxx.bezierCurveTo(38.6644,17.5113,39.791,9.90947,34.009,10.2404);
        // this.ctxxx.bezierCurveTo(31.3412,10.4006,27.1747,12.8883,15.9933,23.6931);
        // this.ctxxx.lineTo(20.4042,12.6213);
        // this.ctxxx.bezierCurveTo(23.6354,4.51774,14.7498,-3.55385,6.6188,1.64571);
        // this.ctxxx.lineTo(0.783641,5.58541);
        // this.ctxxx.bezierCurveTo(-0.00288399,6.08721,-0.236716,7.1442,0.262834,7.94495);
        // this.ctxxx.lineTo(2.09097,10.8277);
        // this.ctxxx.bezierCurveTo(2.59052,11.6284,3.64276,11.8633,4.43992,11.3508);
        // this.ctxxx.lineTo(10.6046,7.19759);
        // this.ctxxx.bezierCurveTo(12.5603,5.94841,14.9305,7.96631,14.0802,10.091);
        // this.ctxxx.lineTo(3.64276,36.3129);
        // this.ctxxx.bezierCurveTo(2.92001,38.1173,3.92974,41,6.79949,41);
        // this.ctxxx.bezierCurveTo(7.68167,41,8.55323,40.6583,9.20158,39.9964);
        // this.ctxxx.bezierCurveTo(13.6869,35.4908,25.6442,23.9066,31.6494,19.0914);
        // this.ctxxx.bezierCurveTo(31.4156,22.1343,31.4262,25.38,33.8389,28.0385);
        // this.ctxxx.bezierCurveTo(35.4651,29.8322,37.8034,30.7397,40.8007,30.7397);
        // this.ctxxx.bezierCurveTo(44.5845,30.7397,48.0283,29.1809,51.6739,27.5367);
        // this.ctxxx.bezierCurveTo(55.1814,25.9565,62.1963,20.8531,66.384,20.5114);
        // this.ctxxx.bezierCurveTo(67.2875,20.4367,67.9996,19.732,67.9996,18.8245);
        // this.ctxxx.lineTo(67.9996,15.3973);
        // this.ctxxx.bezierCurveTo(68.0209,14.4257,67.2024,13.6036,66.2352,13.6677);
        // this.ctxxx.closePath();
        //  this.ctxxx.fill();
        //  this.ctxxx.stroke();
        //  this.ctxxx.restore();
        this.firstFlag = false;
    } else {         
        this.ctxxx.clearRect(0,0,this.canvasss.nativeElement.width,this.canvasss.nativeElement.height);
    }
  }

  	// drawPencil(ctx) {
    //     ctx.beginPath();
    //     ctx.moveTo(prevX, prevY);
    //     ctx.lineTo(currX, currY);
    //     ctx.strokeStyle = x;
    //     ctx.lineWidth = y;
    //     ctx.stroke();
    //     ctx.closePath();
    // }
    
    limpiarCanva() {
        // var m = confirm("Want to clear");
        // if (m) {
          this.ctxxx.clearRect(0,0,this.canvasss.nativeElement.width,this.canvasss.nativeElement.height);
        //     document.getElementById("canvasimg").style.display = "none";
        // }
    }
    
    // save() {
    //     document.getElementById("canvasimg").style.border = "2px solid";
    //     var dataURL = canvas.toDataURL();
    //     document.getElementById("canvasimg").src = dataURL;
    //     document.getElementById("canvasimg").style.display = "inline";
    // }
    
  closeModal(okStatus) {
    //okStatus: true/false, define si se aceptó el mensaje del modal o se cerró el modal sin aceptar
    this.modalCtrl.dismiss({
      aceptar: okStatus
    });
  }

}
