export { UsuarioServiceProvider } from "./usuario-service/usuario-service";
export { VideosServiceProvider } from "./videos-service/videos-service";
export { FuncionesServiceProvider } from "./funciones-service/funciones-service";
// export { PreguntasServiceProvider } from "./preguntas-service/preguntas-service";
export { EstadisticasServiceProvider } from "./estadisticas-service/estadisticas-service";
export { CategoriaServiceProvider } from "./categoria-service/categoria-service";
export { MenuServiceProvider } from "./menu-service/menu-service";
export { ConexionServiceProvider } from "./conexion-service/conexion-service";
export { LoaderProvider } from "./loader/loader";
export { ZoomServiceProvider } from "./videollamada-service/zoom-service";
export { PrinActivoServiceService } from "./prin-activo-service/prin-activo-service.service";
