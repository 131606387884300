import { EventEmitter, Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuServiceProvider {


  private showMenu: any;
  public _showMenu = new EventEmitter<Boolean>();
  public select_page = new EventEmitter<String>();
  public select_group = new EventEmitter<Number | null>();
  public select_sub_group = new EventEmitter<Number | null>();

  private _fullscreen: boolean = false;
  public hideFooter = new BehaviorSubject<boolean>(this._fullscreen);
  currentHideFooter = this.hideFooter.asObservable();

  private selectedPage = new BehaviorSubject<string>("");
  selectedPageObservable$ = this.selectedPage.asObservable();
  
  constructor() {
    this.select_page.subscribe(index => {
      this.selectedPage.next(index);
      localStorage.setItem('select_page', index);
    });
    this.select_group.subscribe(index => {
      localStorage.setItem('select_group', index)
    });
    this.select_sub_group.subscribe(index => {
      localStorage.setItem('select_sub_group', index)
    });
  }

  changeHideFooter(state:boolean) {
    this.hideFooter.next(state);
  }

  public getShowMenu(): any {
    return this.showMenu;
  }

  public setShowMenu(showMenu:any) {
    this.showMenu = showMenu;
  }

}
