import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from "src/app/api.service";
import * as moment from "moment";

@Component({
  selector: 'app-nps-modal',
  templateUrl: './nps-modal.component.html',
  styleUrls: ['./nps-modal.component.scss'],
})
export class NpsModalComponent implements OnInit {

  @Input() slug_seccion: String
  @Input() slug_accion_completada: String
  @Output() onClose = new EventEmitter();
  movil: Boolean = false;
  mobile: Boolean = window.innerWidth <= 768;
  
  SelectOption: any = [];
  initNPS: boolean = false
  sendNPS: boolean = false
  sendButton: boolean = false
  closeNPS: boolean = false
  selectStar: number = 0
  selectType: any = ''
  selectOption: any = []
  response: any = ''
  nps: any = null
  date_nps: any = {}
  date_now: any = null
  sections: any = {}
  section1: any= null
  section2: any= null
  section3: any= null
  stars: any = [
    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }
  ];

  constructor(private activatedRoute: ActivatedRoute, public platform: Platform, private modalController: ModalController, private api: ApiService) { 
    if (this.platform.is("cordova") || this.platform.is("mobileweb")) this.movil = true;
  }
  async ngOnInit(){
    setTimeout(() => {
    this.sections = localStorage.getItem("nps") ? JSON.parse(localStorage.getItem("nps")) : null;
    this.date_nps = localStorage.getItem("date_nps") ? JSON.parse(localStorage.getItem("date_nps")) : {query: null, sent:null};
    this.date_now = moment(new Date()).format("L");
    if(this.date_nps.sent !== this.date_now && this.sections){
      this.nps = this.sections.find( e => e.seccion == this.slug_seccion && e.accion_completada == this.slug_accion_completada);
      if( this.nps ){
        this.section1 = this.nps.encuesta.secciones.findIndex( section => section.seccion === 1);
        this.section2 = this.nps.encuesta.secciones.findIndex( section => section.seccion === 2);
        this.section3 = this.nps.encuesta.secciones.findIndex( section => section.seccion === 3);
       
      } else this.closeModal();
    }
    else {
      this.closeModal();
    }
    }, 1000);
    
    addEventListener("resize", () => {
      this.mobile = innerWidth <= 768;
      this.addClassMobile()
    });
  }

  ngAfterContentInit(){
    setTimeout(() => {
      this.addClassMobile();
    }, 3000);
  }

  SelectStars(id){
    this.selectStar = id;
    this.selectType = this.nps.encuesta.secciones[this.section1].estrellas.detractor >= this.selectStar ? 'detractor' : this.nps.encuesta.secciones[this.section1].estrellas.neutral >= this.selectStar ? 'neutral' : 'promotor';
    this.selectOption = [];
    const cards = document.getElementsByClassName('card-option');
    Array.from(cards).forEach(element => {
      element.className = 'card-option';
    });  
    this.Send();
  }

  SelectOptions(option) {    
    const el = document.getElementById(option.id);
    if(el.classList.contains('option-selected')){
      this.selectOption = this.selectOption.filter(opt => opt != option.id);
      el.classList.remove('option-selected');
    } else {
      this.selectOption.push(option.id);
      el.classList.add('option-selected');
    }
    this.Send();
  }

  async Send(){
    const user = JSON.parse(localStorage.getItem("user_data"));
    const send_nps = {
      encuesta: {
        id: this.nps.encuesta.id,
        nombre: this.nps.encuesta.nombre,
        version: this.nps.encuesta.version,
        valoracion: this.selectStar !== 0 ? this.selectStar : null,
        caracteristicas: this.selectOption.length >0 ? this.selectOption : null,
        comentario: this.response !== "" ? this.response : null
      },
      usuario: {
        nombre: user.nombre,
        dni: user.dni,
        email: user.email ? user.email : "",
        workspace: user.workspace.name,
        subworkspace: '',
        platform: 'app'
      }
    }
    this.api
					.sendNPS(send_nps)
					.then((res: any) => {
						if(!res.error){
              // if(!this.closeNPS){
               this.date_nps.sent = this.date_now;
               localStorage.setItem("date_nps", JSON.stringify(this.date_nps));
              // }
              if(this.sendButton){
                this.sendNPS = true;
                setTimeout(() => {
                  this.closeModal();
                }, 3000);
              }
            }
					})
					.catch((err) => {
            console.log('error');
					});
  }

  addClassMobile() {
    const btn = document.getElementById('btn-encuesta');
    if(btn) {
      if (this.mobile && !this.initNPS) {
        btn.classList.add("button-secondary-mobile")
        btn.innerHTML =  `<img src="${'assets/nps/icono.svg'}"/>` ;
      } else {
        btn.classList.remove("button-secondary-mobile")
        btn.innerHTML = this.nps.encuesta.texto_boton ? this.nps.encuesta.texto_boton : 'Ayúdanos a mejorar';
      }
    }
  }

  closeModal() {
    this.closeNPS = true;
    if(this.initNPS && !this.sendNPS) {
      this.Send();
    } 
    const main = document.getElementById('main');
    if ( main) {
      main.classList.remove('addMb');
    } 
    this.onClose.emit();
  }

  initializeNPS(){
    const main = document.getElementById('main');
    if ( main && this.mobile) main.classList.add('addMb');
    this.initNPS = true;
    this.addClassMobile();
  }
}
