import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NumeroDniDirective } from "./directives/numero-dni.directive";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

/** Plugins Native **/
import { Network } from "@ionic-native/network/ngx";
import { NativePageTransitions } from "@ionic-native/native-page-transitions/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Downloader } from "@ionic-native/downloader/ngx";
import { ComponentsModule } from "./components/components.module";
// import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer/ngx";
import { File } from '@ionic-native/file/ngx';
import { HTTP } from "@ionic-native/http/ngx";

/** Plugins **/
import { IonicStorageModule } from "@ionic/storage";
import { PipesModule } from "./pipes/pipes.module";
//import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { defineCustomElements } from '@teamhive/pdf-viewer/dist/loader';
import { JoyrideModule, JoyrideStepComponent } from "ngx-joyride";
import { ChartsModule, ThemeService } from "ng2-charts";
//import { GoogleChartsModule } from "angular-google-charts";
import { SQLite } from "@ionic-native/sqlite/ngx";  
defineCustomElements(window);

/** Services **/
import { InterceptorService } from "./service/interceptors/interceptor.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SideMenuMobileComponent } from "./components/side-menu-mobile/side-menu-mobile.component";
import { ScrollbarThemeModule } from "./directives/scrollbar-theme.directive";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { MatIconModule } from "@angular/material/icon";
import { EventTrackerModule } from './directives/event-tracker.directive';
import { MultipleOptionModalComponent } from "./components/multiple-option-modal/multiple-option-modal.component";
import { WebView } from '@ionic-native/ionic-webview/ngx';

// import { FirebaseConfig } from "@ionic-native/firebase-config/ngx";

@NgModule({
	declarations: [
		AppComponent,
		NumeroDniDirective,
	],
	entryComponents: [SideMenuMobileComponent, JoyrideStepComponent, MultipleOptionModalComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		MatIconModule,
		IonicStorageModule.forRoot(),
		JoyrideModule.forRoot(),
		ComponentsModule,
		PipesModule,
		ScrollbarThemeModule,
		ChartsModule,
		EventTrackerModule,
		//GoogleChartsModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
	],
	providers: [
		StatusBar,
		SplashScreen,
		Network,
		NativePageTransitions,
		AppVersion,
		//BackgroundMode,
		Market,
		LocalNotifications,
		Keyboard,
		InAppBrowser,
		Downloader,
		//FileTransfer,
		//FileTransferObject,
		File,
		HTTP,
		// Zoom,
		AndroidPermissions,
		// FirebaseConfig,
		FCM,
		ThemeService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true,
		},
		SQLite,
		WebView,
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
