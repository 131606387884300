import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAlumno'
})
export class DateAlumnoPipe implements PipeTransform {

  MESES = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  transform(value: any, ...args: any[]): any {
    let fortmatDate = value.replace(/-/g, "/");
    let parseado = new Date(Date.parse(fortmatDate))
    //let parseado = new Date(Date.parse(value))
    let fecha = `${parseado.getDate()} ${this.MESES[parseado.getMonth()]}`
    return fecha;
  }

}
