import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiService } from "src/app/api.service";
const helper = new JwtHelperService();

@Injectable({ providedIn: "root" })
export class UsuarioServiceProvider {
	[x: string]: any;
	private most_est_conx: any = null;
	private id_usuario: string = null;
	private ruta_base: string = null;
	private ruta_rest: string = null;
	public data_usuario$ = new EventEmitter<any>();
	private ruta_imagen: string;
	private data_pruebas: any[] = [];
	private data_encuestas: any[] = [];
	public data_novedades: any[] = [];
	public data_carrera: string = null;
	private bucket_base_url: string = null;
	private config_data: string = null;
	private data_temas: any[] = [];
	private _token: string = null;
	private loggedIn = new BehaviorSubject<boolean>(false);
	private platform_type: string = null;

	public configBrand = new BehaviorSubject<any>(null);
	public configBrand$ = this.configBrand.asObservable();

	constructor(
		private http: HttpClient,
		private api: ApiService,
		) {
		this.genera_rutas();
		this.check_token();
	}

	// Funcion para generar ruta_base
	genera_rutas() {
		let apiConnectors = this.api.getApiUrls;

		if(environment.APP_MULTIMARCA && (apiConnectors.serviceBase === "" || !apiConnectors.serviceBase)) {
			const apiUrlForBrand = localStorage.getItem("api_urls");
			if(apiUrlForBrand) {
				this.api.setApiUrls = JSON.parse(apiUrlForBrand);
				apiConnectors = this.api.getApiUrls;
			}
		}
		this.ruta_base = apiConnectors.serviceBaseGestor;
		this.ruta_rest = apiConnectors.serviceBaseRest;
		//  this.ruta_base = environment.SERVICE_BASE_GESTOR;
		//  this.ruta_rest = environment.SERVICE_BASE_REST;
	}

	/**
	 * Getters and Setters
	 */
	public set token(v: string) {
		this._token = v;
	}

	public get token(): string {
		return this._token;
	}

	set setIdUsuario(id_usuario: string) {
		this.id_usuario = id_usuario;
	}

	get getIdUsuario(): string {
		return this.id_usuario;
	}

	set setRutaBase(ruta_base: string) {
		this.ruta_base = ruta_base;
	}
	get getRutaBase(): string {
		return this.ruta_base;
	}

	set setRutaRest(ruta_rest: string) {
		this.ruta_rest = ruta_rest;
	}
	get getRutaRest(): string {
		return this.ruta_rest;
	}

	set setConfigData(config_data: string) {
		this.config_data = config_data;
	}
	get getConfigData(): string {
		return this.config_data;
	}

	set setBucketBaseUrl(bucket_base_url: string) {
		this.bucket_base_url = bucket_base_url;
	}
	get getBucketBaseUrl(): string {
		return this.bucket_base_url;
	}

	get rutaImagen(): string {
		return this.ruta_imagen;
	}
	get datapruebas(): any {
		return this.data_pruebas;
	}
	set datapruebas(v: any) {
		this.data_pruebas = v;
	}
	get dataencuestas(): any {
		return this.data_encuestas;
	}
	set dataencuestas(v: any) {
		this.data_encuestas = v;
	}

	get temas_libres(): any {
		return this.data_temas;
	}

	set temas_libres(temas: any) {
		this.data_temas = temas;
	}

	set setNovedades(data: any) {
		this.data_novedades = data;
	}
	get getNovedades(): any {
		return this.data_novedades;
	}

	set setMostrarEstado(estado: any) {
		this.most_est_conx = estado;
	}
	get getMostrarEstado(): any {
		return this.most_est_conx;
	}

	set setDataCarrera(v: any) {
		this.data_carrera = v;
	}

	get getDataCarrera(): any {
		return this.data_carrera;
	}

	get isLogged(): Observable<boolean> {
		return this.loggedIn.asObservable();
	}

	set setPlatformType(platform_type: string) {
		this.platform_type = platform_type;
	}

	get getPlatformType(): string {
		return this.platform_type;
	}


	/**
	 * Guardar Storage - ID del usuario
	 */

	setConfig(configData){
		this.configBrand.next(configData);
	}
	save_storage() {
		localStorage.setItem("id_usuario", this.id_usuario);
		localStorage.setItem("config_data", this.config_data);
		localStorage.setItem("bucket_base_url", this.bucket_base_url);
		localStorage.setItem("token", this.token);
		localStorage.setItem("most_est_conx", this.most_est_conx);
		localStorage.setItem("platform_type", this.platform_type);
		this.loggedIn.next(true);
	}
	/**
	 * Cargar los datos del Storage - ID del usuario
	 */
	load_storage() {
		this.id_usuario = localStorage.getItem("id_usuario");
		this.config_data = localStorage.getItem("config_data");
		this.bucket_base_url = localStorage.getItem("bucket_base_url");
		let data_usuario = JSON.parse(localStorage.getItem("user_data"));
		this.data_usuario$.emit(data_usuario);
		this.token = localStorage.getItem("token");
		this.most_est_conx = localStorage.getItem("most_est_conx");
		this.platform_type = localStorage.getItem("platform_type");
	}

	clearStorage(removeApiUrls = true) {
		localStorage.removeItem("id_usuario");
		localStorage.removeItem("config_data");
		localStorage.removeItem("bucket_base_url");
		localStorage.removeItem("user_data");
		localStorage.removeItem("token");
		localStorage.removeItem("most_est_conx");
		localStorage.removeItem("select_page");
		localStorage.removeItem("nps");
		localStorage.removeItem("config_ambiente");
		localStorage.removeItem("loggedCustomConfig");
		localStorage.removeItem("platform_type");
		if(removeApiUrls) {
			localStorage.removeItem("api_urls");
		}
		this.loggedIn.next(false);
	}

	check_token() {
		const userToken = localStorage.getItem("token");
		const isExpired = helper.isTokenExpired(userToken);
		// console.log(isExpired);
		if (isExpired) {
			this.clearStorage();
		} else {
			this.loggedIn.next(true);
		}
	}
}
