import { Component, NgZone } from "@angular/core";
import { ConexionServiceProvider, UsuarioServiceProvider } from "src/app/service/index.providers";
import { LoaderProvider } from "src/app/service/loader/loader";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";

@Component({
	selector: "app-alert-conection",
	templateUrl: "./alert-conection.component.html",
	styleUrls: ["./alert-conection.component.scss"],
})
export class AlertConectionComponent {
	a = 1;
	b = 1;

	isConnected = false;

	constructor(
		private ngzone: NgZone,
		private conexionService: ConexionServiceProvider,
		private uP: UsuarioServiceProvider,
		private loader: LoaderProvider,
		private router: Router,
		private api: ApiService
	) {
		console.log("constructor");
		//this.checkConection();
	}

	checkConection() {
		// this.conexionService.getNetworkStatus().subscribe((connected: boolean) => {
		// 	console.log("conexion service");
		// 	this.isConnected = connected;

		// 	if (!this.isConnected) {
		// 		console.log("Por favor enciende tu conexión a internet");
		// 		this.ngzone.run(() => {
		// 			this.b = 3;
		// 			setTimeout(() => {
		// 				this.a = 3;
		// 				this.uP.setMostrarEstado = "NoMostrado";
		// 			}, 500);
		// 		});
		// 	} else {
		// 		console.log("Conectado a internet");
		// 		this.ngzone.run(() => {
		// 			this.b = 1;
		// 			setTimeout(() => {
		// 				if (this.uP.getMostrarEstado == null || this.uP.getMostrarEstado == "NoMostrado") {
		// 					this.a = 2;
		// 					//this.b = 2;
		// 					//this.b = 1;
		// 				}
		// 			}, 500);
		// 			setTimeout(() => {
		// 				this.uP.setMostrarEstado = "Mostrado";
		// 				console.log("ya pasaron 4 seg");
		// 				this.a = 1;
		// 			}, 3500);
		// 		});
		// 	}
		// });
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.uP.clearStorage();
			this.router.navigate(["login"]);
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

	reconectar() {
		console.log("reconectar");
		this.loader.show();
		this.a = 1;
		this.checkConection();
		setTimeout(() => {
			this.loader.hide();
		}, 500);
	}
}
