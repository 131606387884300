import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
const win: any = window;
@Pipe({
	name: "renderLocalFile",
})

export class RenderLocalFilePipe implements PipeTransform {
  private win: any = window;
	constructor(private sanitizer: DomSanitizer, private file: File, private webView: WebView) {}

	transform(value: any, ...args: any[]): any {
		//   console.log("***************pipe render********",this.webView.convertFileSrc(value) ); 
		const hasLocalPath = args[0];
		// console.log("***************pipe render media url********",hasLocalPath,value); 
		if(hasLocalPath) {
			return win.Ionic.WebView.convertFileSrc(value);
		} else {
			return value;
		}	 
	}
}

// export class RenderLocalFilePipe implements PipeTransform {
//   private win: any = window;
// 	constructor(private sanitizer: DomSanitizer, private file: File, private webView: WebView) {}

// 	transform(value) {

//       console.log("***************pipe render********",value); 
//       console.log("***************pipe render********",this.webView.convertFileSrc(value) ); 
//       // try {
//       //   const resBase64 = await this.file.readAsDataURL(this.file.dataDirectory, `media/${docName}`);
//       //   console.log("sanitizer base 64");          
// 			// 	trustUrl = this.sanitizer.bypassSecurityTrustUrl(resBase64);
        
//       // } catch (error) {
//       //   console.log("error :: No se pudo recuperar el file MEDIA descargado!!!!", error);
//       //   trustUrl = value;
//       // }
		

// 		// return this.sanitizer.bypassSecurityTrustUrl(value);
//     return win.Ionic.WebView.convertFileSrc(value);
// 	}

//    async reproducirArchivo(ruta:string, encryptionKey: string) {
//       try {
//         let filePath: string[] = ruta.split("/");
// 		    let docName = `${filePath[filePath.length - 1]}`;
//         // Leer el archivo guardado desde el sistema de archivos del dispositivo
//         const rutaDirectorio = this.file.dataDirectory;
//         const rutaArchivo =  '/encryptedmedia/' + docName;        
//         console.log("ubicacion:", rutaDirectorio," " ,rutaArchivo );

//         // const reader = new FileReader();
//         // reader.onload = () => {
//           // const decryptedData = this.decryptData(reader.result as ArrayBuffer, decryptionKey, iv);
//           // Manipula la data descifrada según sea necesario
//           // Aquí puedes asignarla a un elemento de video HTML, por ejemplo
//         // };

//         const decryptionKey = 'course-offline'; // Reemplaza con tu clave secreta
//         // const iv = crypto.getRandomValues(new Uint8Array(16)); // Debes generar el IV adecuadamente
//         const iv = this.hexToBytes('3eafb3e2c7d36af6aeb1f36b0ae31768');

//         const encryptedBlob = await this.file.readAsArrayBuffer(rutaDirectorio, rutaArchivo);      
//         // reader.readAsArrayBuffer(encryptedBlob);
//         const decryptedData = this.decryptData(encryptedBlob, decryptionKey, iv);
//         // const contenidoDesencriptado = AES.decrypt(contenidoArchivo, encryptionKey).toString(enc.Utf8);
//         const urlArchivoDesencriptado = URL.createObjectURL(new Blob([contenidoDesencriptado], { type: 'video/mp4' }));



//       } catch (error) {
//         console.error('Error al reproducir el archivo:', error);
//       }
//    }

//    decryptAndSaveMp4(encryptedFilePath: string): Promise<any> {
//     const decryptionKey = 'tu_clave_de_cifrado_secreta'; // Reemplaza con tu clave secreta
//     const iv = crypto.getRandomValues(new Uint8Array(16)); // Debes generar el IV adecuadamente

//     return this.http.get(encryptedFilePath, { responseType: 'blob' }).toPromise().then((encryptedBlob) => {
//       const reader = new FileReader();
//       reader.onload = () => {
//         const decryptedData = this.decryptData(reader.result as ArrayBuffer, decryptionKey, iv);
//         // Manipula la data descifrada según sea necesario
//         // Aquí puedes asignarla a un elemento de video HTML, por ejemplo
//       };
//       reader.readAsArrayBuffer(encryptedBlob);
//     });
//   }

//    private decryptData(encryptedData: ArrayBuffer, decryptionKey: string, iv: Uint8Array): string {
//     const decryptedArray = new Uint8Array(encryptedData.byteLength);
//     const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(decryptionKey), iv);
//     let decryptedSize = decipher.update(encryptedData, decryptedArray);
//     decryptedSize += decipher.final(decryptedArray);
//     return Buffer.from(decryptedArray.slice(0, decryptedSize)).toString();
//   }

//   hexToBytes(hex) {
//     const bytes = [];
//     for (let i = 0; i < hex.length; i += 2) {
//         bytes.push(parseInt(hex.substr(i, 2), 16));
//     }
//     return String.fromCharCode.apply(String, bytes);
//   }
// }

// transform(value) {

// 	console.log("***************pipe render********",value); 
// 	console.log("***************pipe render********",this.webView.convertFileSrc(value) ); 		

// 	  // return this.sanitizer.bypassSecurityTrustUrl(value);
//   return win.Ionic.WebView.convertFileSrc(value);
// }


