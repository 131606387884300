import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sesion-card',
  templateUrl: './sesion-card.component.html',
  styleUrls: ['./sesion-card.component.scss'],
})
export class SesionCardComponent implements OnInit {
  @Input() typeOfSession: String; 
  @Input() title: String; 
  @Input() subtitle: String; 
  @Input() typeDescription: String = ''; 
  @Input() coverImg; 
  @Input() customClass: String; //recibe: class
  @Input() isFisrtCard: boolean; 



  constructor() { }

  ngOnInit() {    
  }

}
