import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {

  @Input() public parentForm:FormGroup;
  @Input() public parentFormControlName: string = "";
  @Input() selectOptions:string[];
  @Input() placeholder:string;
  @Input() optionHoverColor:string;
  @Output() selectChange = new EventEmitter<string>();

  openSelect: boolean = false;
  selectedValue:string;
  
  constructor() { }

  ngOnInit() { }

  onSelectedOption(selectedItem){
    this.openSelect = false;
    this.selectedValue = selectedItem;      
    this.selectChange.emit(this.selectedValue);  
  }

  handleClickedOutside(){
    this.openSelect = false;
  }

  // onSelectChange(selectedValue: any) {
  //   selectedValue.stopPropagation();
  //   this.SelectedValue = selectedValue.detail.value;  
  //   this.selectChange.emit(this.SelectedValue);  
  // }



}
