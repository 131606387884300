import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayrandon'
})
export class ArrayrandonPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let myarray = value;
    let currentIndex = myarray.length;
    let temporayValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random()*currentIndex);
      currentIndex -= 1;

      temporayValue = myarray[currentIndex];
      myarray[currentIndex] = myarray[randomIndex];
      myarray[randomIndex] = temporayValue;
    }
    return myarray;
  }

}
