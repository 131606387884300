import { Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ApiService } from "src/app/api.service";
import { FuncionesServiceProvider, UsuarioServiceProvider } from "src/app/service/index.providers";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class CheckPagesGuard implements CanActivate {
	constructor(
		private up: UsuarioServiceProvider,
		private router: Router,
		private fp: FuncionesServiceProvider,
		private api: ApiService,
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.up.isLogged.pipe(
			take(1),
			map((isLogged: boolean) => {
				const apiConnectors = this.api.getApiUrls;
				if (!isLogged ) {
					//console.log('Login off');
					this.router.navigate(["/login"]);
					return false;
				}
				if (isLogged && environment.APP_MULTIMARCA && (apiConnectors.serviceBase === "" || !apiConnectors.serviceBase || apiConnectors.serviceBaseRest === "" || !apiConnectors.serviceBaseRest || apiConnectors.serviceBaseGestor === "" || !apiConnectors.serviceBaseGestor)  ) {
					//console.log('Login off - urls');
					this.up.clearStorage();
					this.router.navigate(["/login"]);
					return false;
				}
				return true;
			})
		);
	}
}
