import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from '@ionic/angular';

@Component({
  selector: "app-confirmar-evaluacion",
  templateUrl: "./confirmar-evaluacion.component.html",
  styleUrls: ["./confirmar-evaluacion.component.scss"],
})
export class ConfirmarEvaluacionComponent implements OnInit {
  @Input() type: string  = "qualified";
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }

  confirmar() {
    this.modalCtrl.dismiss({
      confirm: true
    });
  }
  cancelar() {
    this.modalCtrl.dismiss()
  }
  enviarEvaluacion() {
    let param = [
      { opc: "4", preg_id: "12025" },
      { opc: "1", preg_id: "12028" },
      { opc: "3", preg_id: "12030" },
      { opc: "3", preg_id: "12029" },
      { opc: "1", preg_id: "12026" },
    ];
  }
}
