import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CheckLoginGuard } from "./guards/login/check-login.guard";
import { CheckPagesGuard } from "./guards/page/check-pages.guard";
import { AppPreloadService } from "./service/app-preload.service"
import { OfflineMsgGuard } from "./guards/offline/offline-msg.guard";

const routes: Routes = [
	{
		path: "",
		loadChildren: () =>
			import("./pages/anuncios/anuncios.module").then((m) => m.AnunciosPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'anuncios',
        }
	},
	{
		path: "login",
		loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
		canActivate: [CheckLoginGuard],
		data: {
            title: 'login',
			preload: true,
        }

	},
	{
		path: "anuncios",
		loadChildren: () =>
			import("./pages/anuncios/anuncios.module").then((m) => m.AnunciosPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'anuncios',
			preload: true,
        }
	},
	{
		path: "ayuda",
		loadChildren: () => import("./pages/ayuda/ayuda.module").then((m) => m.AyudaPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'ayuda'
        }
	},
	{
		path: "certificados",
		loadChildren: () => import("./pages/diploma/diploma.module").then((m) => m.DiplomaPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'certificados'
        }
	},
	{
		path: "encuesta-libre",
		loadChildren: () =>
			import("./pages/encuesta-libre/encuesta-libre.module").then((m) => m.EncuestaLibrePageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'encuesta-libre'
        }
	},
	{
		path: "encuesta-preguntas",
		loadChildren: () =>
			import("./pages/encuesta-preguntas/encuesta-preguntas.module").then(
				(m) => m.EncuestaPreguntasPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'encuesta-preguntas'
        }
	},
	{
		path: "encuesta-resultado/:curso_id",
		loadChildren: () =>
			import("./pages/encuesta-resultado/encuesta-resultado.module").then(
				(m) => m.EncuestaResultadoPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'encuesta-resultado',
        }
	},
	{
		path: "evaluacion-curso/:tema_id",
		loadChildren: () =>
			import("./pages/evaluacion/evaluacion.module").then((m) => m.EvaluacionPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'evaluacion'
        }
	},
	{
		path: "evaluacion-respuestas/:tema_id",
		loadChildren: () =>
			import("./pages/evaluacion-respuestas/evaluacion-respuestas.module").then(
				(m) => m.EvaluacionRespuestasPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'evaluacion-respuestas'
        }
	},
	{
		path: "evaluacion-resultado",
		loadChildren: () =>
			import("./pages/evaluacion-resultado/evaluacion-resultado.module").then(
				(m) => m.EvaluacionResultadoPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'evaluacion-resultado',
			preload: true,
        }
	},
	{
		path: "glosario",
		loadChildren: () =>
			import("./pages/glosario/glosario.module").then((m) => m.GlosarioPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'glosario'
        }
	},
	{
		path: "login-loading",
		loadChildren: () =>
			import("./pages/login-loading/login-loading.module").then((m) => m.LoginLoadingPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'login-loading',
			preload: true,
        }
	},
	{
		path: "progreso",
		loadChildren: () =>
			import("./pages/progreso/progreso.module").then((m) => m.ProgresoPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'progreso',
			preload: true,
        }
	},
	{
		path: "progreso-detalle",
		loadChildren: () =>
			import("./pages/progreso/progreso-detalle/progreso-detalle.module").then(
				(m) => m.ProgresoDetallePageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'progreso-detalle'
        }
	},
	{
		path: "ranking",
		loadChildren: () => import("./pages/ranking/ranking.module").then((m) => m.RankingPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'ranking'
        }
	},
	{
		path: "temalibre-detalle",
		loadChildren: () =>
			import("./pages/temalibre-detalle/temalibre-detalle.module").then(
				(m) => m.TemalibreDetallePageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'temalibre-detalle'
        }
	},
	{
		path: "temas-libres",
		loadChildren: () =>
			import("./pages/temas-libres/temas-libres.module").then((m) => m.TemasLibresPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'temas-libres'
        }
	},
	{
		path: "conferencias/:tipo/:id",
		loadChildren: () =>
			import("./pages/conferencias/conferencias.module").then((m) => m.ConferenciasPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'conferencias'
        }
	},
	{
		path: "conferencias/:tipo",
		loadChildren: () =>
			import("./pages/conferencias/conferencias.module").then((m) => m.ConferenciasPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'conferencias'
        }
	},
	{
		path: "conferencias-detalles",
		loadChildren: () =>
			import("./pages/conferencias-detalles/conferencias-detalles.module").then(
				(m) => m.ConferenciasDetallesPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'conferencias-detalles'
        }
	},
	{
		path: "lista-reuniones",
		loadChildren: () =>
			import("./pages/lista-reuniones/lista-reuniones.module").then(
				(m) => m.ListaReunionesPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-reuniones'
        }
	},
	{
		path: "vista-conferencia",
		loadChildren: () =>
			import("./pages/vista-conferencia/vista-conferencia.module").then(
				(m) => m.VistaConferenciaPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'vista-conferencia'
        }
	},
	{
		path: "conferencias-editar",
		loadChildren: () =>
			import("./pages/conferencias-editar/conferencias-editar.module").then(
				(m) => m.ConferenciasEditarPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'conferencia-editar'
        }
	},
	{
		path: "iframe-form",
		loadChildren: () =>
			import("./pages/iframe-form/iframe-form.module").then((m) => m.IframeFormPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'iframe-form'
        }
	},
	{
		path: "cursos",
		loadChildren: () => import("./pages/cursos/cursos.module").then((m) => m.CursosPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'cursos',
			preload: true,
        }
	},
	{
		path: "escuela/:escuela/cursos/:curso/tema/:tema",
		loadChildren: () => import("./pages/tema/tema.module").then((m) => m.TemaPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'escuela-tema',
			preload: true,
        }
	},
	{
		path: "escuela/:escuela/cursos/:curso/tema",
		loadChildren: () => import("./pages/tema/tema.module").then((m) => m.TemaPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'escuela-tema',
			preload: true,
        }
	},
	{
		path: "faq",
		loadChildren: () => import("./pages/faq/faq.module").then((m) => m.FaqPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'faq'
        }
	},
	{
		path: "adjuntar-archivo",
		loadChildren: () =>
			import("./pages/adjuntar-archivo/adjuntar-archivo.module").then(
				(m) => m.AdjuntarArchivoPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'adjuntar-archivo'
        }
	},
	{
		path: "vademecum",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'vademecum'
        },
		loadChildren: () =>
			import("./pages/vademecun/vademecun.module").then((m) => m.VademecunPageModule),
	},
	{
		path: "documentos-y-protocolos",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'documentos-y-protocolos'
        },
		loadChildren: () =>
			import("./pages/vademecun/vademecun.module").then((m) => m.VademecunPageModule),

	},
	{
		path: "lista-alumnos",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-alumnos'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-alumnos/lista-alumnos.module").then(
				(m) => m.ListaAlumnosPageModule
			),
	},
	{
		path: "alumno",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'alumno'
        },
		loadChildren: () =>
			import("./pages/checklist/alumno/alumno.module").then((m) => m.AlumnoPageModule),
	},
	{
		path: "checklist",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/checklist/checklist.module").then((m) => m.ChecklistPageModule),
	},
	{
		path: "lista-alumnos-checklist",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-alumnos-checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-alumnos-checklist/lista-alumnos-checklist.module").then(
				(m) => m.ListaAlumnosChecklistPageModule
			),
	},
	{
		path: "lista-checklists",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-checklists'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-checklists/lista-checklists.module").then(
				(m) => m.ListaChecklistsPageModule
			),
	},
	{
		path: "slides-bienvenida",
		data: {
            title: 'slides-bienvenida'
        },
		loadChildren: () =>
			import("./pages/slides-bienvenida/slides-bienvenida.module").then(
				(m) => m.SlidesBienvenidaPageModule
			),
	},
	{
		path: "videoteca",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'videoteca'
        },
		loadChildren: () =>
			import("./pages/videoteca/videoteca.module").then((m) => m.VideotecaPageModule),
	},
	{
		path: "multimedia/:id",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'multimedia'
        },
		loadChildren: () =>
			import("./pages/videoteca/multimedia/multimedia.module").then((m) => m.MultimediaPageModule),
	},
	{
		path: "notificaciones",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'notificaciones'
        },
		loadChildren: () =>
			import("./pages/notificaciones/notificaciones.module").then(
				(m) => m.NotificacionesPageModule
			),
	},
	{
		path: "reportes",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'reportes'
        },
		loadChildren: () =>
			import("./pages/reportes/reportes.module").then((m) => m.ReportesPageModule),
	},
	{
		path: "buscador",
		data: {
            title: 'buscador'
        },
		loadChildren: () =>
			import("./pages/videoteca/buscador/buscador.module").then((m) => m.BuscadorPageModule),
	},
	{
		path: "recuperar-contrasenia",
		data: {
            title: 'recuperar-contrasenia'
        },
		loadChildren: () =>
			import("./pages/recuperar-contra/recuperar-contra.module").then(
				(m) => m.RecuperarContraPageModule
			),
	},
	{
		path: "cambiar-contrasenia/:token",
		data: {
            title: 'cambiar-contrasenia'
        },
		loadChildren: () =>
			import("./pages/cambiar-contra/cambiar-contra.module").then((m) => m.CambiarContraPageModule),
	},
  {
    path: 'login-ayuda',
	data: {
		title: 'login-ayuda'
	},
    loadChildren: () => import('./pages/login-ayuda/login-ayuda.module').then( m => m.LoginAyudaPageModule)
  },
  {
    path: 'ayuda-coordinador',
	data: {
		title: 'ayuda-coordinador'
	},
    loadChildren: () => import('./pages/ayuda-coordinador/ayuda-coordinador.module').then( m => m.AyudaCoordinadorPageModule)
  },
  {
    path: 'solicitud-enviada',
	data: {
		title: 'solicitud-enviada'
	},
    loadChildren: () => import('./pages/solicitud-enviada/solicitud-enviada.module').then( m => m.SolicitudEnviadaPageModule)
  },
  {
    path: 'historial-de-cursos',
	canActivate: [CheckPagesGuard],
	data: {
		title: 'historial'
	},
    loadChildren: () => import('./pages/historico/historico.module').then( m => m.HistoricoPageModule)
  },
  {
    path: 'validacion-usuario',
	data: {
		title: 'validacion-usuario'
	},
    loadChildren: () => import('./pages/validacion-usuario/validacion-usuario.module').then( m => m.ValidacionUsuarioPageModule)
  },
  {
    path: 'validacion-quiz',
	data: {
		title: 'validacion-quiz'
	},
    loadChildren: () => import('./pages/validacion-quiz/validacion-quiz.module').then( m => m.ValidacionQuizPageModule)
  },
  {
    path: 'error-validacion',
    data: {
      title: 'error-validacion'
    },
    loadChildren: () => import('./pages/validacion-quiz/error-validacion/error-validacion/error-validacion.module').then( m => m.ErrorValidacionPageModule)
  },
  {
    path: 'auth/login/external',
	data: {
		title: 'login-external'
	},

    loadChildren: () => import('./pages/login-external/login-external.module').then( m => m.LoginExternalPageModule)
  },
  {
    path: 'tareas',
	canActivate: [CheckPagesGuard],
	data: {
		title: 'tareas'
	},
    loadChildren: () => import('./pages/tareas/tareas.module').then( m => m.TareasPageModule)
  },
  {
    path: 'usuario/mi-perfil',
	canActivate: [CheckPagesGuard],
	data: {
		title: 'perfil'
	},
    loadChildren: () => import('./pages/usuario-perfil/usuario-perfil.module').then( m => m.UsuarioPerfilPageModule)
  },
  {
    path: 'usuario/cambiar-contrasena',
	canActivate: [CheckPagesGuard],
	data: {
		title: 'cambiar-contrasena-perfil'
	},
    loadChildren: () => import('./pages/actualizar-contrasenia/actualizar-contrasenia.module').then( m => m.ActualizarContraseniaPageModule)
  },
  {
    path: 'modulo-reconocimiento',
	canActivate: [CheckPagesGuard],
	data: {
		title: 'reconocimiento'
	},
    loadChildren: () => import('./pages/modulo-reconocimiento/modulo-reconocimiento.module').then( m => m.ModuloReconocimientoPageModule)
  },
	{
		path: "beneficios",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'beneficios'
        },
		loadChildren: () =>
			import("./pages/beneficio/lista-beneficios/lista-beneficios.module").then(
				(m) => m.ListaBeneficiosPageModule
			),
	},
	{
		path: "beneficio",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'beneficio'
        },
		loadChildren: () =>
			import("./pages/beneficio/beneficio/beneficio.module").then((m) => m.BeneficioPageModule),
	},
	{
		path: "beneficio/speaker",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'speaker'
        },
		loadChildren: () =>
			import("./pages/beneficio/speaker/speaker.module").then((m) => m.SpeakerPageModule),
	},
	{
		path: 'register',
		data: {
			title: 'register'
		},
		loadChildren: () => import('./pages/register-user/register-user.module').then( m => m.RegisterUserPageModule)
	},
	{
		path: "introduccion",
		loadChildren: () =>
			import("./pages/induccion/introduccion/introduccion.module").then(
				(m) => m.IntroduccionPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'introduccion'
        }
	},
	{
		path: "home",
		loadChildren: () =>
			import("./pages/induccion/home/home.module").then(
				(m) => m.HomePageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'home'
        }
	},
	{
		path: "induccion/:induccion/tema/:tema",
		loadChildren: () => import("./pages/tema-ind/tema-ind.module").then((m) => m.TemaIndPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'induccion-tema',
			preload: true,
        }
	},
	{
		path: "certificado",
		loadChildren: () => import("./pages/diploma/ver-diploma/ver-diploma.module").then((m) => m.VerDiplomaPageModule),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'certificado'
        }
	},
	{
		path: "procesos",
		loadChildren: () =>
			import("./pages/induccion/procesos/procesos.module").then(
				(m) => m.ProcesosPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'procesos'
        }
	},
	{
		path: "proceso/:proceso",
		loadChildren: () =>
			import("./pages/induccion/proceso/proceso.module").then(
				(m) => m.ProcesoPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'proceso'
        }
	},
	{
		path: "proceso-detalle/:proceso",
		loadChildren: () =>
			import("./pages/induccion/proceso-detalle/proceso-detalle.module").then(
				(m) => m.ProcesoDetallePageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'proceso-detalle'
        }
	},
	{
		path: "colaborador/:colaborador",
		loadChildren: () =>
			import("./pages/induccion/colaborador/colaborador.module").then(
				(m) => m.ColaboradorPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'colaborador'
        }
	},
	{
		path: "lista-colaboradores",
		loadChildren: () =>
			import("./pages/induccion/lista-colaboradores/lista-colaboradores.module").then(
				(m) => m.ListaColaboradoresPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-colaboradores'
        }
	},
	{
		path: "induccion/encuesta-preguntas",
		loadChildren: () =>
			import("./pages/encuesta-preguntas/encuesta-preguntas.module").then(
				(m) => m.EncuestaPreguntasPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'induccion-encuesta-preguntas'
        }
	},
	{
		path: 'cursos-presenciales',
		data: {
			title: 'presenciales'
		},
		canActivate: [CheckPagesGuard],
		loadChildren: () => import('./pages/cursos-presenciales/cursos-presenciales.module').then( m => m.CursosPresencialesPageModule)
	},
	{
		path: 'offline-message',
		data: {
			title: 'offline-status'
		},
		// canActivate: [CheckPagesGuard],
		canActivate: [CheckPagesGuard, OfflineMsgGuard],
		loadChildren: () => import('./pages/offline-message/offline-message.module').then( m => m.OfflineMessagePageModule)
	},
];

@NgModule({
	//imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadService })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
