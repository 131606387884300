import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-contenido-media',
  templateUrl: './contenido-media.component.html',
  styleUrls: ['./contenido-media.component.scss'],
})
export class ContenidoMediaComponent implements OnInit {

  media: any = []
  selected: any

  constructor(private navParams: NavParams, private popoverController: PopoverController) { }

  ngOnInit() {
    this.media = this.navParams.get('media')
    console.log(this.media);
    this.selected = this.navParams.get('selected')
    console.log(this.selected);
  }

  verificarIconMedia(tipo) {
    switch (tipo) {
      case "youtube":
        return "logo-youtube";
        break;
      case "vimeo":
        return "logo-vimeo";
        break;
      case "pdf":
        return "document-text-sharp";
        break;
      case "scorm":
        return "school-sharp";
        break;

      default:
        break;
    }
  }

  verMedia(index) {
    this.popoverController.dismiss({ index })
  }

}
