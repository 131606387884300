import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Network } from "@ionic-native/network/ngx";
import { BehaviorSubject, Observable } from "rxjs";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";

/*
  Generated class for the ConexionServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export enum ConnectionStatus {
	Online,
	Offline,
}
@Injectable({ providedIn: "root" })
export class ConexionServiceProvider {
	//   private hasConnection = new BehaviorSubject(false);
	private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

	constructor(
		private network: Network,
		private platform: Platform,
		public http: HttpClient,
		public router: Router
	) {
        console.log("constructor network service");
        
		// this.platform.ready().then(() => {
        //     this.initializeNetworkEvents();
		// 	let status = this.network.type !== "none" ? ConnectionStatus.Online : ConnectionStatus.Offline;
		// 	this.status.next(status);
        //     console.log("run network service2: ", this.status.getValue());
		// });
	}

	public initializeNetworkEvents() {
		this.network.onDisconnect().subscribe(() => {
			if (this.status.getValue() === ConnectionStatus.Online) {
				console.log("WE ARE OFFLINE");
				this.updateNetworkStatus(ConnectionStatus.Offline);
			}
		});

		this.network.onConnect().subscribe(() => {
			if (this.status.getValue() === ConnectionStatus.Offline) {
				console.log("WE ARE ONLINE");
                const forceOffline = localStorage.getItem("chooseOffline");
                if(forceOffline && forceOffline === "true" && this.network.type !== "none") {
                    console.log("offline switch is active");                    
                } else {
                    this.updateNetworkStatus(ConnectionStatus.Online);
                };
			}
		});

        let status = this.network.type !== "none" ? ConnectionStatus.Online : ConnectionStatus.Offline;
		this.status.next(status);
        console.log("initialize network service22: ", this.status.getValue());
		if (status == ConnectionStatus.Offline) {
			console.log("**offline - redirect from service!!**");
            //si tiene coneción
            if( this.network.type !== "none") {
                this.router.navigate(["cursos"]);                
            } else{                
                this.router.navigate(["offline-message"]);
            }
		}

	}

	public async updateNetworkStatus(status: ConnectionStatus, fromTopic = false) {
		this.status.next(status);

		if (status == ConnectionStatus.Offline && !fromTopic) {
			console.log("offline - redirect from service!!");
            //si tiene coneción
            if( this.network.type !== "none") {
                this.router.navigate(["cursos"]);                
            } else{                
                this.router.navigate(["offline-message"]);
            }
		}
		if (status == ConnectionStatus.Online && !fromTopic) {
			console.log("online - redirect from service!!");
			this.router.navigate(["anuncios"]);
		}
	}

	public onNetworkChange(): Observable<ConnectionStatus> {
		return this.status.asObservable();
	}

	public getCurrentNetworkStatus(): ConnectionStatus {
		return this.status.getValue();
	}
	public getDeviceNetworkType(): string {
		return this.network.type;
	}

	/* 

    // if (this.platform.is('cordova')) {
    //   // on Device
    //   this.network.onConnect().subscribe(() => {
    //       console.log('network was connected Device :-)');
    //       this.hasConnection.next(true);
    //       return;
    //   });
    //   this.network.onDisconnect().subscribe(() => {
    //       console.log('network was disconnected Device :-(');
    //       this.hasConnection.next(false);
    //       return;
    //   });
    // }

    // this.testNetworkConnection();
  

  public getNetworkType(): string {
    return this.network.type;
}

public getNetworkStatus(): Observable<boolean> {
    return this.hasConnection.asObservable();
}

private getNetworkTestRequest(): Observable<any> {
    return this.http.get('https://jsonplaceholder.typicode.com/todos/1');
}

public async testNetworkConnection() {
    try {
        this.getNetworkTestRequest().subscribe(
        success => {
            // console.log('Request to Google Test  success', success);
                this.hasConnection.next(true);
            return;
        }, error => {
            // console.log('Request to Google Test fails', error);
            this.hasConnection.next(false);
            return;
        });
    } catch (err) {
        console.log('err testNetworkConnection', err);
        this.hasConnection.next(false);
        return;
   }
}

*/
}
