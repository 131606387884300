import { Component, Inject, OnInit, ApplicationRef } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { UsuarioServiceProvider, FuncionesServiceProvider } from "./service/index.providers";
import { Router, NavigationEnd } from "@angular/router";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Storage } from "@ionic/storage";
import { NavController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
// import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { environment } from "../environments/environment";
import { MenuServiceProvider } from 'src/app/service/index.providers';
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { filter } from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { ApiService } from "../app/api.service";
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';

import * as moment from "moment";
import { MultipleOptionModalComponent } from "./components/multiple-option-modal/multiple-option-modal.component";
import { Network } from '@ionic-native/network/ngx';
// import 'scorm-again';
import { ConexionServiceProvider, ConnectionStatus } from './service/conexion-service/conexion-service';
import { OfflineService } from "./service/offline-service/offline.service";
// declare const window: any;
declare var cordova: any;

declare const gtag: Function;
// declare var navigator
@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {

	public rootPage: any;
	keyboardHidden = true;
	SDK_KEY = "8da6o4WWAQrFYQNrkK4d5xXaaEHjY0L1BzW3";
	SDK_SECRET = "Nf1EVzndUCF0JGIjtpNZVcpsOabERp7vB4BF";
	fullscreen: boolean = false;
	userID: any = null;
	domain: any = null;
	platformIsIOS: boolean | null = null;
	showImpersonationBar:boolean = false;
	impersonationBarTitle;

	config_empresa: any = null;
	showNotification: boolean = false;
	data_usuario;
	initials;
	userFullName:string = "";
	checkUpdateInterval;
	webCurrentVersion;
	webAvailableVersion;
	userIsLogged = false;

	//**config Cursalab del espacio multimarca**
	defaultConfigData = {
	color_primario_app: "#5458e9",
	color_secundario_app: "#58c1e5",
	color_terciario_app: "#372db4",
	titulo_login_app: "Bienvenido a",
	subtitulo_login_app: "Cursalab",
	logo_cursalab_position: "top-left",
	form_login_position: "center",
	form_login_transparency: 100,
	show_blog_btn: 1,
	female_logo: 'assets/img/avatar_mujer.svg',
	male_logo: 'assets/img/avatar_hombre.svg',
	fondo_app: 'assets/img/bg-cursalab.png',
	enrolled_courses_logo: 'assets/icon/cursos_inscritos.svg',
	completed_courses_logo:'assets/icon/cursos_completados.svg',
	diplomas_logo: 'assets/icon/diplomas_obtenidas.svg',
	icono: "assets/icon/favicon.png",
	id: 1,
	link_pbi: null,
	logo_cursalab: 'assets/img/logo_by_cursalab.svg',
	logo_app: 'assets/img/logo-cursalab.svg',
	titulo: "Cursalab",
	titulo_login: "Cursalab",
	url_app_web: null,
	template: "template-default"
	}

	constructor(
		public platform: Platform,
		private titleService: Title,
		private splashScreen: SplashScreen,
		private up: UsuarioServiceProvider,
		private fp: FuncionesServiceProvider,
		public router: Router,
		private fcm: FCM,
		private Storage: Storage,
		public navCtrl: NavController,
		private iab: InAppBrowser,
		private LocalNotifications: LocalNotifications,
		private mp: MenuServiceProvider,
		private keyboard: Keyboard, // private fireConfig: FirebaseConfig
		private api: ApiService,
		private statusBar: StatusBar,
		private ns: NotificacionService,
		private swUpdate: SwUpdate,
		private appRef: ApplicationRef,
		private alertCtrl: AlertController,
		@Inject(DOCUMENT) private document: any,
		private modalCtrl: ModalController,
		private network: Network,
		private networkService: ConexionServiceProvider,
		private offlineHttpService: OfflineService,
		// private firebaseDynamicLinks: FirebaseDynamicLinks,
	) {
		this.up.configBrand.subscribe((res) => {
			this.config_empresa = res;
		});
		console.log("Ver. web: ", environment.WEB_VERSION);
		this.domain = this.document.location.hostname;
		this.addGoogleAnalyticsScript();

		if(swUpdate.isEnabled && ( this.platform.is("desktop") || !this.platform.is("cordova") ) ) {
				this.swUpdate.available.subscribe((event) => {
					console.log('current version::: ', event.current.hash);
					console.log('available version::: ', event.available.hash);
					if( ( this.webCurrentVersion && this.webCurrentVersion === event.current.hash) &&
						(this.webAvailableVersion && this.webAvailableVersion === event.available.hash)
					) { console.log("already informed")	}
					else {
						this.webCurrentVersion = event.current.hash;
						this.webAvailableVersion = event.available.hash;
						console.log('reload message');
						this.reloadMessage();
					}
				});

				const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
				const everyTwoHour$ = interval(7200000);

				const everyTwoHourOnceAppIsStable$ = concat(appIsStable$, everyTwoHour$);

				this.checkUpdateInterval = everyTwoHourOnceAppIsStable$.subscribe( (intervalNumber) => {
					//console.log("int:",intervalNumber)
					if( intervalNumber !== true ) {
						try {
							swUpdate.checkForUpdate().then(() => {
								console.log('checking for updates');
							});

						} catch (err) {
							console.error('Failed to check for updates', err);
						}
					}
				});
		}

		this.mp.currentHideFooter.subscribe((state) => (this.fullscreen = state));

		this.ns.currentHideNotification.subscribe((state) => (this.showNotification = state));

		this.keyboard.onKeyboardDidHide().subscribe(() => {
			this.keyboardHidden = true;
		});

		this.keyboard.onKeyboardWillShow().subscribe(() => {
			this.keyboardHidden = false;
		});

		this.up.isLogged.subscribe((res) => {
			//console.log("logueado: ",res);
			this.userIsLogged = res;			
			if (res) {
				this.data_usuario = JSON.parse(localStorage.getItem("user_data"));
				if (this.data_usuario) {				
					this.initials = this.data_usuario.nombre.charAt(0).toUpperCase() + this.data_usuario.apellido.charAt(0).toUpperCase();
					this.userFullName =  this.data_usuario.full_name;
				};

				this.fp.cargarFunciones();
				this.userID = this.up.getIdUsuario;
				//validate impersonation session
		 		const configData = JSON.parse(localStorage.getItem("config_data"));
				if(configData.impersonation) {
					setTimeout(() => {
						this.showImpersonationBar = configData.impersonation.show_bar;
						this.impersonationBarTitle = configData.impersonation.show_title;
					}, 1000);
				};

				this.platform.ready().then(() =>{
					if (this.platform.is("cordova")){						
					console.log("USUARIO LOGUEADO INIT HTTP OFFLINE SERVICE");					
					this.offlineHttpService.checkNewtworkStatus();
					}
				})

			} else {
				this.initials = "";
				this.userFullName = "";

				this.showImpersonationBar = false;
				this.impersonationBarTitle = "";

				if(environment.APP_MULTIMARCA) {
					// this.config_empresa = this.defaultConfigData;
					// this.up.setConfig(this.config_empresa);
					// this.settingsForBrand();
					this.setCustomConfigForBrand(this.defaultConfigData);
				} else {
					this.getConfig();
				}
			}
		});

		//Add dynamic title for selected pages
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				let title:string | any = this.getTitle(this.router.routerState, this.router.routerState.root);
				if (this.config_empresa) {
					title.length > 0
					? titleService.setTitle(this.config_empresa.titulo ? this.config_empresa.titulo : "Cursalab" + " | " + title[0])
					: titleService.setTitle(this.config_empresa.titulo ? this.config_empresa.titulo : "Cursalab");
				}
				//title.length > 0 ? titleService.setTitle( 'WeConnect 2.0 | ' + title[0]) :  titleService.setTitle( 'WeConnect 2.0');
				if(title.length > 0 && title[0] !== "login") {
					const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
					if (element) {
						element.style.visibility = "hidden";
					}
				};

				const user = JSON.parse(localStorage.getItem("user_data"));
				if (user) {
					const nps = localStorage.getItem("nps") ? JSON.parse(localStorage.getItem("nps")) : null;
					const date = localStorage.getItem("date_nps")
						? JSON.parse(localStorage.getItem("date_nps"))
						: { query: null, sent: null };
					const date_now = moment(new Date()).format("L");
					if (!nps || (nps && date.query !== date_now)) {
						const dni = user.dni;
						this.api
							.listNPS("app", "2", dni)
							.then((res: any) => {
								const date_nps = { query: date_now, sent: null };
								if (date.sent === date_now) {
									date_nps.sent = date.sent;
								}
								localStorage.setItem("date_nps", JSON.stringify(date_nps));
								localStorage.setItem("nps", JSON.stringify(res.data));
							})
							.catch((err) => {
								console.log(err);
								localStorage.setItem("nps", JSON.stringify([]));
							});
					}
				}
				this.ns.changeShowNotification(false);
			}
		});

		//Add analytics
		if (this.domain != "localhost") {
			const navEndEvents$ = this.router.events.pipe(
				filter((event) => event instanceof NavigationEnd)
			);

			navEndEvents$.subscribe((event: NavigationEnd) => {
					if (this.userID) {
						gtag("config", environment.GA_TRACKING_ID, {
							user_id: this.userID,
							page_path: event.urlAfterRedirects,
						});
					} else {
						gtag("config", environment.GA_TRACKING_ID);
						gtag("event", "page_view", {
							page_path: event.urlAfterRedirects,
						});
					}
			});
		}


		this.platform.ready().then(() => {
			console.log("platform is ready");
			if (this.platform.is("ios")) {
				this.platformIsIOS = true;
				this.notificationPermission();
				// let status bar overlay webview
				this.statusBar.overlaysWebView(false);
				// set status bar to white
				this.statusBar.backgroundColorByHexString("#000000");
			}
			if (this.platform.is("android")) {				
				this.notificationPermission();
			}
			if (this.platform.is("cordova")) {
				this.Storage.get("token_fcm").then((val) => {
					if (val != null) {
						if (val.status === "true") {
							let data = {
								status: "false",
								token: "",
							};
							this.Storage.set("token_fcm", data);
						} else {
							this.fcm.getToken().then((token) => {
								let data = {
									status: "true",
									token: token,
								};
								this.Storage.set("token_fcm", data);
							});
						}
					} else {
						this.fcm.getToken().then((token) => {
							let data = {
								status: "true",
								token: token,
							};
							this.Storage.set("token_fcm", data);
						});
					}
				});

				this.fcm.onNotification().subscribe((data) => {
					console.log(data);
					if (data.wasTapped) {
						if (data.tipo == "notificacion_formulario") {
							this.iab.create(data.link, "_system");
						}
					} else {
						this.LocalNotifications.schedule({
							title: data.title,
							text: data.body,
							data: { link: data.link, tipo: data.tipo },
						});
					}
				});

				this.LocalNotifications.on("click").subscribe((res) => {
					if (res.data.tipo == "notificacion_formulario") {
						if (this.platform.is("ios")) {
							location.href = res.data.link;
						} else {
							window.location.href = res.data.link;
						}
					}
				});

				cordova.plugins.firebase.dynamiclinks.getDynamicLink()
				.then((payload) => {
					if (payload) {
						console.log("Read dynamic link data on app start::", payload);
						this.mp.select_page.emit("lista-reuniones");
						this.mp.select_group.emit(null);
						this.router.navigate(["lista-reuniones"]);

					} else {
						console.log("App wasn't started from a dynamic link!");
					}
				})
				.catch( error => {
					console.log(error);	
				});

				this.checkNewtworkStatus();
				
			}

			

			this.splashScreen.hide();
		});


	}

	ngOnInit() {
		//configuracion de ambiente por marca
		if(environment.APP_MULTIMARCA) {

			const configAmbiente = JSON.parse(localStorage.getItem("config_ambiente"));
			// this.config_empresa = configAmbiente && this.userIsLogged ? configAmbiente : this.defaultConfigData;
			// this.up.setConfig(this.config_empresa);
			// this.settingsForBrand();

			//* config de ambiente especifica x workspace */
			if(configAmbiente && this.userIsLogged) {
				// const loggedCustomConfigInStorage = JSON.parse(localStorage.getItem("loggedCustomConfig"));
				// if(loggedCustomConfigInStorage) {
				// 	let formatedData = {
				// 		...configAmbiente,
				// 		completed_courses_logo : loggedCustomConfigInStorage.completed_courses_logo,
				// 		enrolled_courses_logo : loggedCustomConfigInStorage.enrolled_courses_logo,
				// 		diplomas_logo : loggedCustomConfigInStorage.diplomas_logo,
				// 		color_primario_app: loggedCustomConfigInStorage.color_primario_app,
				// 		color_secundario_app: loggedCustomConfigInStorage.color_secundario_app,
				// 		color_terciario_app: loggedCustomConfigInStorage.color_terciario_app,
				// 	}

				// 	this.setCustomConfigForBrand(formatedData);
				// }
				// else {
				this.setCustomConfigForBrand(configAmbiente);
			} else {
				this.setCustomConfigForBrand(this.defaultConfigData);
			}


			let apiConnectors = this.api.getApiUrls;
			const apiUrlForBrand = localStorage.getItem("api_urls");
			if(apiConnectors.serviceBase === "" || !apiConnectors.serviceBase) {
				if(apiUrlForBrand) this.api.setApiUrls = JSON.parse(apiUrlForBrand);
			}

			const certificateUrlBase = this.up.getRutaBase;
			if(!certificateUrlBase && apiUrlForBrand){
				this.up.genera_rutas();
			}

		}
		else {
			if(this.userIsLogged) {
				this.getConfig();
			}

		}

	}

	checkNewtworkStatus() {	
		console.log("*app.ts - initializeNetworkEvents!!!*");
		this.networkService.initializeNetworkEvents();
		
		// this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
		// 	if (status == ConnectionStatus.Online) {
		// 		console.log("online app.ts");
				
		// 		//   this.offlineManager.checkForEvents().subscribe();
		// 	} else {
		// 		console.log("offline app.ts");

		// 	}
		// });

	}

	async reloadMessage() {
		const modalAvailable = await this.modalCtrl.create({
			component: MultipleOptionModalComponent,
			backdropDismiss: false,
			cssClass: "reload-modal",
			componentProps: {
				firstButtonText: "Actualizar ahora",
				secondButtonText: "Actualizar más tarde",
				customStyle: "w-10/12 sm:w-88 md:w-96 custom-reload-modal-width",
			},
		});
		await modalAvailable.present();

		const { data } = await modalAvailable.onWillDismiss();

		if(data.option === "first") {
			console.log("reload")
			this.swUpdate.activateUpdate()
			.then(() => window.location.reload())
			.catch((error) => window.location.reload());
		}
	}

	setCustomConfigForBrand(configData) {

		this.config_empresa = configData;
		if(configData && !configData.color_primario_app) {
			//en caso lleguen los colores en null
			this.config_empresa.color_primario_app = "#5458e9";
			this.config_empresa.color_secundario_app = "#58c1e5";
		}
		this.up.setConfig(this.config_empresa);
		let title = this.getTitle(this.router.routerState, this.router.routerState.root);
		title.length
			? this.titleService.setTitle(this.config_empresa.titulo ? this.config_empresa.titulo : "Cursalab" + " | " + title[0])
			: this.titleService.setTitle(this.config_empresa.titulo ? this.config_empresa.titulo : "Cursalab");
		if(this.config_empresa.icono) {
			const favicon = document.getElementById("favicon") as HTMLLinkElement;
			favicon.href = this.config_empresa.icono;
		};

		let result_pri;
		let result_sec;
		let rgb;

		try {
			result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
				this.config_empresa.color_primario_app
			);
			result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
				this.config_empresa.color_secundario_app
			);
	
			//red,green,blue(primary & secondary)
			rgb = {
				rp: parseInt(result_pri[1], 16),
				gp: parseInt(result_pri[2], 16),
				bp: parseInt(result_pri[3], 16),
				rs: parseInt(result_sec[1], 16),
				gs: parseInt(result_sec[2], 16),
				bs: parseInt(result_sec[3], 16),
			};	
			
		} catch (error) {
			console.log(error);		
			result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
				"#5458e9"
			);
			result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
				"#58c1e5"
			);

			//red,green,blue(primary & secondary)
			rgb = {
				rp: parseInt(result_pri[1], 16),
				gp: parseInt(result_pri[2], 16),
				bp: parseInt(result_pri[3], 16),
				rs: parseInt(result_sec[1], 16),
				gs: parseInt(result_sec[2], 16),
				bs: parseInt(result_sec[3], 16),
			};	
		}

		let rootelement = this.document.documentElement;
		// colores de config
		rootelement.style.setProperty("--primary", this.config_empresa.color_primario_app);
		rootelement.style.setProperty(
			"--ion-color-primary",
			this.config_empresa.color_primario_app
		);
		//color secundario
		rootelement.style.setProperty("--secondary", this.config_empresa.color_secundario_app);
		rootelement.style.setProperty(
			"--ion-color-secondary",
			this.config_empresa.color_secundario_app
		);
		rootelement.style.setProperty(
			"--tertiary",
			this.config_empresa.color_terciario_app
				? this.config_empresa.color_terciario_app
				: this.config_empresa.color_secundario_app
		);
		rootelement.style.setProperty(
			"--ion-color-tertiary",
			this.config_empresa.color_terciario_app
				? this.config_empresa.color_terciario_app
				: this.config_empresa.color_secundario_app
		);
		if (this.config_empresa.template !== "template-inretail") {
			rootelement.style.setProperty(
				"--quaternary",
				this.config_empresa.color_terciario_app ? this.config_empresa.color_terciario_app : this.config_empresa.color_secundario_app
			);
		}

		// variantes
		rootelement.style.setProperty(
			"--primary-focused",
			`rgb(${5.4*rgb.rp/10}, ${6.4*rgb.gp/10}, ${8.8*rgb.bp/10})`
			//`rgb(${6*rgb.rp/10}, ${6*rgb.gp/10}, ${6*rgb.bp/10})`   original
		);
		rootelement.style.setProperty(
			"--primary-light",
			`rgb(${18*rgb.rp/10}, ${17.5*rgb.gp/10}, ${12.2*rgb.bp/10})`
		);
		rootelement.style.setProperty(
			"--primary-light-variant",
			`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.3)`
		);
		rootelement.style.setProperty(
			"--primary-ultra-light-variant",
			`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.12)`
		);
		rootelement.style.setProperty(
			"--secondary-focused",
			`rgb(${8.6*rgb.rs/10}, ${9.6*rgb.gs/10}, ${9.4*rgb.bs/10})`
		);
		rootelement.style.setProperty(
			"--secondary-blur",
			`rgba(${0*rgb.rs/10}, ${9*rgb.gs/10}, ${11.5*rgb.bs/10}, 0.65)`
		);

	}

	async getConfig() {

		const loggedCustomConfigInStorage = JSON.parse(localStorage.getItem("loggedCustomConfig"));
		let response;
		try {
			response = await this.api.getConfig();

			//***config ambiente */
			if(loggedCustomConfigInStorage) {
				let formatedData = {
					...response,
					completed_courses_logo : loggedCustomConfigInStorage.completed_courses_logo ? loggedCustomConfigInStorage.completed_courses_logo : response.completed_courses_logo,
					enrolled_courses_logo : loggedCustomConfigInStorage.enrolled_courses_logo ? loggedCustomConfigInStorage.enrolled_courses_logo : response.enrolled_courses_logo,
					diplomas_logo : loggedCustomConfigInStorage.diplomas_logo ? loggedCustomConfigInStorage.diplomas_logo : response.diplomas_logo,
					color_primario_app: loggedCustomConfigInStorage.color_primario_app ? loggedCustomConfigInStorage.color_primario_app : response.color_primario_app,
					color_secundario_app: loggedCustomConfigInStorage.color_secundario_app ? loggedCustomConfigInStorage.color_secundario_app : response.color_secundario_app,
					color_terciario_app: loggedCustomConfigInStorage.color_terciario_app ? loggedCustomConfigInStorage.color_terciario_app : response.color_terciario_app,
				};

				this.setCustomConfigForBrand(formatedData);
			} else {
				this.setCustomConfigForBrand(response);
			}
		} catch (error) {
			console.log("ERROR - default config: ",error);
			if(loggedCustomConfigInStorage) {
				let formatedData = {
					...this.defaultConfigData,
					completed_courses_logo : loggedCustomConfigInStorage.completed_courses_logo,
					enrolled_courses_logo : loggedCustomConfigInStorage.enrolled_courses_logo,
					diplomas_logo : loggedCustomConfigInStorage.diplomas_logo,
					color_primario_app: loggedCustomConfigInStorage.color_primario_app,
					color_secundario_app: loggedCustomConfigInStorage.color_secundario_app,
					color_terciario_app: loggedCustomConfigInStorage.color_terciario_app,
				};

				this.setCustomConfigForBrand(formatedData);
			} else {
				this.setCustomConfigForBrand(this.defaultConfigData);
			}
		}
	}

	async notificationPermission() {
		const wasPermissionGiven: boolean = await this.fcm.requestPushPermission({
			ios9Support: {
				timeout: 10, // How long it will wait for a decision from the user before returning `false`
				interval: 0.3, // How long between each permission verification
			},
		});

		console.log("*STATUS PERMISO: ", wasPermissionGiven);
		
	}

	// collect that title data properties from all child routes
	getTitle(state, parent) {
		var data = [];

		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}
		return data;
	}
	async logoutImpersonation() {
		  await this.api.logout_token()
		  .then((res) => {
			this.up.clearStorage();
			this.showImpersonationBar = false;
			this.impersonationBarTitle= "";
			this.router.navigate(["login"]);
			})
			.catch((e) => {
				console.log(e.status, e.url);
			});
	}
	addGoogleAnalyticsScript(){
		if (this.domain != "localhost"){
			let gtagScript: HTMLScriptElement = document.createElement('script');
			gtagScript.async = true;
			gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
			document.head.prepend(gtagScript);

			/** Disable automatic page view hit to fix duplicate page view count  **/
			gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
		}
	}

	ngOnDestroy() {
		if (this.checkUpdateInterval) {
			this.checkUpdateInterval.unsubscribe();
			console.log("OnDestroy unsubscribe interval")
		}

	}
}
