import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FuncionesServiceProvider, UsuarioServiceProvider } from 'src/app/service/index.providers';
import { ConexionServiceProvider, ConnectionStatus} from '../../service/conexion-service/conexion-service';
import { map, take } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class OfflineMsgGuard implements CanActivate {
  constructor(
		private up: UsuarioServiceProvider,
    private networkService: ConexionServiceProvider,
		private router: Router,
		private fp: FuncionesServiceProvider,
		// private api: ApiService,
	) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.networkService.onNetworkChange().pipe(
			take(1),
			map((status: ConnectionStatus) => {
        console.log("offline guard");
        
				if (status == ConnectionStatus.Online) {
					// console.log('Login on');
					this.fp.cargarFunciones();
					this.router.navigate([""]);
					return false;
				}; 
				return true;
			})
		);
  }
  
}
