import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {

  @Input() placeholder:string;
  @Input() customClass:string;
  @Input() selectedTime:string;
  // @Input() public parentForm:FormGroup;
  // @Input() public parentFormControlName: string = "";
  @Output() onSelectedTime = new EventEmitter<string>();

  // selectedTime:string;
  openTimer: boolean = false;
  hours = Array.from({ length: 24 }, (_, index) =>  index  );
  minutes = Array.from({ length: 60 }, (_, index) => index  );
  selectedHour: number | null = null;
  selectedMinute: number | null = null;
  isChanging: boolean = false;
  
  constructor() {
    if( this.selectedTime) {
      this.selectedTime = this.selectedTime;
    //  console.log("edit en picker::",this.selectedTime);
    }
   }

  ngOnInit() { 
    
  }

  setHour(hour) {
    this.selectedHour = hour;
    this.isChanging = true;
  }

  setMinute(minute) {
    this.selectedMinute = minute;
    this.isChanging = true;
  }

  setTime() {
    this.isChanging = false;
    // minutes = Array.from({ length: 60 }, (_, index) => index < 10 ? `0${index}`: `${index}` );
    this.selectedTime = `${this.selectedHour < 10 ? '0' + this.selectedHour : this.selectedHour }:${this.selectedMinute < 10 ? '0' + this.selectedMinute : this.selectedMinute}`; 
    //console.log("time",this.selectedTime);
    this.openTimer = false;
    this.onSelectedTime.emit(this.selectedTime);  
  }

  handleClickedOutside(){
    this.openTimer = false;
    this.selectedHour = null;
    this.selectedMinute = null;
  }


}
