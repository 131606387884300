import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { UsuarioServiceProvider } from "../usuario-service/usuario-service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { async } from 'rxjs/internal/scheduler/async';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private up: UsuarioServiceProvider, private router: Router,private modalController: ModalController) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem("token");

    let request = req;
    // let arra_dat = {request.body }
    // console.log(request)
    if (token) {
      request = req.clone({
        setHeaders: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

      });
      // console.log(request);
    }

    // console.log(request);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.up.clearStorage();
          this.router.navigateByUrl("/login");
        }
        return throwError(err);
      })
    );
  }

  async closeModal(){
    await this.modalController.dismiss();
  }
}
