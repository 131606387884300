import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UsuarioServiceProvider } from "../usuario-service/usuario-service";

@Injectable({ providedIn: "root" })
export class EstadisticasServiceProvider {
	private data_ranking: any[] = [];
	private data_ranking_zona: any[] = [];
	private data_ranking_botica: any[] = [];
	progreso_cursos_realizados: any[] = [];
	progreso_cursos_pendientes: any[] = [];
	progreso_resumen: any[] = [];
	private data_progreso_ciclos: any[] = [];
	private data_progreso_extra: any[] = [];

	constructor(public http: HttpClient, private up: UsuarioServiceProvider) {}

	public get listaranking(): any {
		return this.data_ranking;
	}

	public set listaranking(v: any) {
		this.data_ranking = v;
	}

	public get listaranking_zona(): any {
		return this.data_ranking_zona;
	}

	public set listaranking_zona(v: any) {
		this.data_ranking_zona = v;
	}

	public get listaRanking_botica(): any {
		return this.data_ranking_botica;
	}

	public set listaRanking_botica(v: any) {
		this.data_ranking_botica = v;
	}

	public get getProgresoCiclos(): any {
		return this.data_progreso_ciclos;
	}

	public set setProgresoCiclos(v: any) {
		this.data_progreso_ciclos = v;
	}

	public get getProgresoExtra(): any {
		return this.data_progreso_extra;
	}

	public set setProgresoExtra(v: any) {
		this.data_progreso_extra = v;
	}
}
