import { Directive, HostListener, Inject, Input, NgModule  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

declare const gtag: Function; 
@Directive({
  selector: '[eventTracker]'
})
export class EventTrackerDirective {

  @Input('eventTracker') eventTracker :any;
  userData: any = null;
  domain;

  @HostListener('click', ['$event']) onClick($event){
    //todo: descomentar domain 
    if (this.domain != "localhost"){
       // console.log('Tracking is successful', this.userData ? `workspace:${this.userData.workspace.name}` : '');
        gtag("config", environment.GA_TRACKING_ID);
        gtag("event",  `button_clicked_${this.eventTracker.identifier}` , {
          event_category: this.eventTracker.category,
          event_label: this.eventTracker.action,
          value: this.userData ? `workspace:${this.userData.workspace.name}` : '',
        });
    }
  }
  constructor(@Inject(DOCUMENT) private document: any) { 
    this.domain = this.document.location.hostname;		
    this.userData = JSON.parse(localStorage.getItem("user_data"));
  }

}

@NgModule({
  declarations: [ EventTrackerDirective ],
  exports: [ EventTrackerDirective ]
})
export class EventTrackerModule {}
