import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'IconSvg',
  templateUrl: './icon-svg.component.html',
  styleUrls: ['./icon-svg.component.scss'],
})
export class IconSvgComponent implements OnInit {
  @Input() name:string = "";
  @Input() customClass:string = ""; // icon size & color
  constructor() { }

  ngOnInit() { }

}

