import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-leave',
  templateUrl: './popover-leave.component.html',
  styleUrls: ['./popover-leave.component.scss'],
})
export class PopoverLeaveComponent implements OnInit {
  
  tipo: boolean = false

  constructor(
    private navParams: NavParams, 
    private popoverCtrl: PopoverController,
  ) {
    this.tipo = this.navParams.get('tipo')
    console.log('Host', this.tipo);
    
  }

  ngOnInit() {}

  cerrarReunion(tipo: number) {
    if (tipo == 0) {
      this.popoverCtrl.dismiss()
    } else {
      this.popoverCtrl.dismiss({
        leave: tipo
      })
    }
  }

}
