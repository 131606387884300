import { Injectable } from "@angular/core";

import { UsuarioServiceProvider } from "../usuario-service/usuario-service";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class VideosServiceProvider {
	private data_videos: any[] = [];
	private data_tag: any;
	private data_actividad: any;

	constructor(public http: HttpClient, private up: UsuarioServiceProvider) {}

	public get videos(): any {
		return this.data_videos;
	}

	public set videos(videos: any) {
		this.data_videos = videos;
	}

	public get tag(): any {
		return this.data_tag;
	}

	public set tag(tag: any) {
		this.data_tag = tag;
	}

	public get actividad(): any {
		return this.data_actividad;
	}

	public set actividad(act: any) {
		this.data_actividad = act;
	}
}
