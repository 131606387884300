import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SelectBoxServiceService } from '../service/select-box-service.service';

@Component({
  selector: 'app-option-select-box',
  templateUrl: './option-select-box.component.html',
  styleUrls: ['./option-select-box.component.scss'],
})
export class OptionSelectBoxComponent implements OnInit {

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() value: String;
  @Input() customClass: String;

  constructor(private service:SelectBoxServiceService) { }

  ngOnInit() {}

  selectionItem() {
    this.service.open_and_close.emit(false)
    this.change.emit(this.value)
  }

}
